import 'react-awesome-button/dist/styles.css';

import { AwesomeButton } from 'react-awesome-button';
import { Fade } from "react-awesome-reveal";
import { SpecialDaysProps } from '../../types/types';

type Props = {
  day: SpecialDaysProps;
}

const DynamicDay = ({ day }: Props) => {

  const { title, day_information, textLoc, mediaType, image, video_webm, video_mp4, videoPoster } = day;

  return (
    <section
      className={`relative min-h-[150px] max-h-auto w-full flex flex-col items-center py-6 overflow-hidden pb-4`}
    >
      <div
        className={`max-w-5xl grid grid-cols-1 lg:grid-cols-2 gap-x-8 items-center z-[1] mx-auto h-auto`}
      >
        <Fade triggerOnce direction='up' cascade>
          <div
            className={`relative border-transparent	bg-[#856bb9ed] rounded-lg p-5 max-w-[90%] mx-auto my-4 text-center text-white box-shadow shadow-1xl shadow-[#846cb99c] h-fit ${textLoc === 'left' && 'order-1'
              } ${textLoc === 'right' && 'order-2'}}`}
          >

            {/* Title */}
            <p
              className={`lg:text-2xl text-xl font-semibold ${day_information && 'pb-2'
                }`}
            >
              {title}
            </p>

            {/* Making sure day information exists to render div */}
            {day_information && (
              <div
                className={`overflow-scroll py-2 md:overflow-auto md:max-h-full transition-all duration-400 ease-in-out sm:max-h-[100px] max-h-[100px]`}
                dangerouslySetInnerHTML={{ __html: day_information }}
              />
            )}

            <div className="my-4">
              <AwesomeButton
                type="primary"
                href="bookings"
                className="w-fit md:h-fit max-w-full max-h-[30px] font-sm"
              >
                Book Online
              </AwesomeButton>
            </div>
            <div className="bottom-[5px] h-fit absolute contents p-0 m-0 md:hidden"></div>
          </div>
        </Fade>

        <Fade triggerOnce direction='up' delay={100} cascade>

          {mediaType === 'Video' || "Image" || "Animation" ? (
            <div
              className={`overflow-hidden rounded-xl w-auto max-w-[90%]  mx-auto ${textLoc === 'left' && 'order-2'
                } ${textLoc === 'right' && 'order-1'} `}
            >
              {/* Image */}
              {mediaType === 'Image' && image !== false ? (
                <img
                  src={image.url}
                  alt="Special Day Animation"
                  className="w-full h-full"
                  loading="lazy"
                />
              ) : null}

              {/* Animation */}
              {/* {animation ? (
                <img
                  src={animation}
                  alt="Special Day Animation"
                  className="w-full h-full"
                  loading="lazy"
                />
              ) : null} */}

              {/* Video */}
              {mediaType === 'Video' && video_mp4 !== false && video_webm !== false && videoPoster !== false ? (
                <video
                  loop
                  autoPlay
                  muted
                  controls
                  controlsList="nodownload"
                  className="w-full max-h-[300px] rounded-xl object-contain aspect-video"
                  poster={videoPoster.url}
                >
                  <source src={video_webm.url} type={`video/webm`} />
                  <source src={video_mp4.url} type={`video/mp4`} />

                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
          ) : null}
        </Fade>
      </div>
    </section>
  );
};

export default DynamicDay;
